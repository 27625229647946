import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu } from "grommet";
import { ResponsiveContext } from "grommet";

export default function Home() {
  return (
    <>
      <Heading level={1}>Akkadian Study Group</Heading>
      <Heading level={4}>Watch Before First Meeting</Heading>
      <Box direction="row-responsive" gap="small">
        <iframe
          width="95%"
          height="315"
          src="https://www.youtube.com/embed/PfYYraMgiBA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          width="95%"
          height="315"
          src="https://www.youtube.com/embed/0T7LY656vgk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          width="95%"
          src="https://www.youtube.com/embed/q-ZGsqwXsNM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          width="95%"
          height="315"
          src="https://www.youtube.com/embed/jAMRTGv82Zo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Box>
    </>
  );
}
