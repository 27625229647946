import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu } from "grommet";

export default function NavBar() {
  return (
    <Nav
      align="center"
      flex={false}
      background={{ color: "brand" }}
      justify="between"
      direction="row"
      pad="xsmall"
      gap="small"
      margin="none"
    >
      Akkadian Study Group
      <Box
        align="center"
        justify="between"
        pad={{ vertical: "small", horizontal: "large" }}
        round
        fill="vertical"
        direction="row"
        gap="small"
        background={{ color: "dark-1", opacity: "medium" }}
      ></Box>
    </Nav>
  );
}
