import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu, PageHeader } from "grommet";

let announcements = [
  {
    text: "27 Eylül Salı 17.30'da Reading Room'da görüşmek üzere",
    date: "23.09.22",
  },
  {
    text: "We have a website now! Check out... wait you are already here. Cool.",
    date: "22.09.22",
  },
];

export default function Anounce() {
  let comp = announcements.map((entry) => (
    <>
      <Text size="large">➤{entry.text}</Text>
      <Text size="small" alignSelf="end">
        {entry.date}
      </Text>
    </>
  ));
  return (
    <>
      <PageHeader
        title="Announcements"
        subtitle="Announcements for the Akkadian Study Group."
      />
      <Box pad="medium" gap="small">
        {comp}
      </Box>
    </>
  );
}
