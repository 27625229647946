import React from "react";
import { PageHeader, Box, List, Text, Anchor } from "grommet";
import { storage, app } from "../firebaseConfig";
import { ref, getDownloadURL, getStorage, listAll } from "firebase/storage";

const gsReference = ref(
  storage,
  "gs://akkadian-dc194.appspot.com/pdfs/first_8_lessons.pdf"
);

let resources = [
  {
    name: "Grammar of Akkadian by John Huehnergard",
    link: "pdfs/(Harvard Semitic) John Huehnergard - A Grammar of Akkadian-Eisenbrauns (2011).pdf",
  },
  {
    name: "First 8 Lessons of Huehnergard (Please print this out)",
    link: "pdfs/first_8_lessons.pdf",
  },
  {
    name: "A Concise Dictionary of Akkadian",
    link: "pdfs/A_Concise_Dictionary_of_Akkadian.pdf",
  },
  {
    name: "Akkadian Sign List",
    link: "pdfs/Labat R.-Manuel dépigraphie akkadienne.pdf",
  },
];

export default function Resources() {
  const [resourcesLink, setResources] = React.useState([]);
  React.useEffect(() => {
    async function getMyUrls() {
      let newResources = [];
      for (let i = 0; i < resources.length; i++) {
        let url = await getDownloadURL(ref(storage, resources[i].link));
        newResources.push({ name: resources[i].name, link: url });
      }
      setResources(newResources);
    }

    getMyUrls();
  }, []);

  let resourcesList = resourcesLink.map((resource) => (
    <Anchor href={resource.link} target="_blank" size="xlarge">
      {resource.name}
    </Anchor>
  ));

  return (
    <>
      <PageHeader
        title="Materials"
        subtitle="A list of resources. Click to download."
      />
      <Box pad="medium" gap="small">
        {resourcesList}
      </Box>
    </>
  );
}
