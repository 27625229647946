// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQjn8hJfqiJ__C6ef7mcxt0Q26xXhYU_E",
  authDomain: "akkadian-dc194.firebaseapp.com",
  projectId: "akkadian-dc194",
  storageBucket: "akkadian-dc194.appspot.com",
  messagingSenderId: "464580639756",
  appId: "1:464580639756:web:a43f0ba8777f4a2bccc229",
  measurementId: "G-F5BPRRGE7E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

export { storage, app };
