import { Page, PageContent, Grommet, Main } from "grommet";
import { hpe } from "grommet-theme-hpe";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Resources from "./components/Resources";
import Anounce from "./components/Anounce";
import Schedule from "./components/Schedule";

function App() {
  return (
    <Grommet theme={hpe}>
      <Main>
        <Page>
          <NavBar />
          <PageContent>
            <Home />
            <Resources />
            <Anounce />
            <Schedule />
          </PageContent>
        </Page>
      </Main>
    </Grommet>
  );
}

export default App;
